import { connectCall } from "../../pages/actions/actions";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData"

export const useAgentManualCall=()=>{
    const {getTicketData}=UseGetStoreData();
    const handleConnectCall=async(phNum:string)=>{
       if(!getTicketData?.agent?.email || !phNum){
        ShowAlert("warning","Agent Email or Customer Mobile Number Notfound")
       }else{
           const payload = {
            // agentId:getTicketData?.agent?.mobile,//this number picked from the accessToken
            customerNumber:phNum,
          };
          const res=await connectCall(payload,getTicketData._id);
          return ShowAlert("success",res?.message);
       }
    };
    return{
        handleConnectCall
    }
}